import React from 'react';

import { LandingPageWrapper } from 'components/global/LandingPageWrapper';
import { Footer } from 'components/global/Footer';
import { Head } from '../Homepage/components/Head';
import { SEO, SEO_CORE_CONTENT } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';

import { LABOR_DAY_BANNER_CONTENT } from 'components/global/Nav/components';
import {
  BeforeAfterPop,
  POP_REDESIGN_BEFORE_AFTER_CONTENT,
} from 'components/modules/pop-redesign/BeforeAfterPop';
import {
  HeroPop,
  POP_REDESIGN_HERO_CONTENT,
} from 'components/modules/pop-redesign/HeroPop';
import {
  IngredientsPop,
  POP_REDESIGN_INGREDIENTS_CONTENT,
} from 'components/modules/pop-redesign/IngredientsPop';
import {
  ProductsPop,
  POP_REDESIGN_PRODUCTS_CONTENT,
} from 'components/modules/pop-redesign/ProductsPop';
import {
  StepsPop,
  POP_REDESIGN_STEPS_CONTENT,
} from 'components/modules/pop-redesign/StepsPop';
import {
  FooterDisclaimerPop,
  POP_FOOTER_DISCLAIMER_CONTENT,
} from 'components/modules/pop-redesign/FooterDisclaimerPop';

export const LaborDay = () => (
  <LandingPageWrapper>
    <Head />
    <SEO {...SEO_CORE_CONTENT} />
    <Nav bannerProps={[LABOR_DAY_BANNER_CONTENT]} />
    <HeroPop {...POP_REDESIGN_HERO_CONTENT} />
    <StepsPop {...POP_REDESIGN_STEPS_CONTENT} />
    <BeforeAfterPop {...POP_REDESIGN_BEFORE_AFTER_CONTENT} />
    <IngredientsPop {...POP_REDESIGN_INGREDIENTS_CONTENT} />
    <ProductsPop {...POP_REDESIGN_PRODUCTS_CONTENT} />
    <Footer
      disclaimer={<FooterDisclaimerPop {...POP_FOOTER_DISCLAIMER_CONTENT} />}
    />
  </LandingPageWrapper>
);
