import React, { ReactNode } from 'react';

export interface FooterDisclaimerPopProps {
  disclaimer: ReactNode;
}

const moduleName = 'footerDisclaimerPop';

export const FooterDisclaimerPop = ({
  disclaimer,
}: FooterDisclaimerPopProps) => (
  <div data-module={moduleName}>{disclaimer}</div>
);
