import React from 'react';
import styled from '@emotion/styled';

import { FooterDisclaimerPopProps } from '../FooterDisclaimerPop';

export const Superscript = styled.sup`
  font-size: 8px;
`;

export const POP_FOOTER_DISCLAIMER_CONTENT: FooterDisclaimerPopProps = {
  disclaimer: (
    <React.Fragment>
      <Superscript>▵</Superscript>Online reviews as of 12/03/2021. *Among 856
      customers subscribed to Curology for 3+ months. Self-reported. New
      subscribers only. Subscription required and subject to consultation.
    </React.Fragment>
  ),
};

export const ACNE_FOOTER_DISCLAIMER_CONTENT: FooterDisclaimerPopProps = {
  disclaimer: (
    <React.Fragment>
      <Superscript>▵</Superscript>Online reviews as of 12/03/2021. *Among 856
      customers subscribed to Curology for 3+ months. Self-reported.
    </React.Fragment>
  ),
};
